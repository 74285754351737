import React from "react";
import { useTranslation } from "react-i18next";
import chatIcon from "assets/chat.svg";
import "./SingleStepAccordion.scss";

const Step5 = (props) => {
  const { t, i18n } = useTranslation();
  const { inClinic } = props;

  return (
    <section
      className="single-step-accordion-container"
      id="scrollspyAccordionStep5"
      ref={props.refProp}
    >
      <h2 id="abortion-checklist-headingFive">
        <button
          className="accordion-button accordion-header"
          data-bs-toggle="collapse"
          data-bs-target="#abortion-checklist-collapseFive"
          aria-expanded="false"
          aria-controls="abortion-checklist-collapseFive"
        >
          <span className="accordion-step-number">
            {t("accordionStep5.stepNumber")}
            <span className="hide-it-on-mobile">:</span>
          </span>
          <span className="accordion-step-title">
            {t("accordionStep5.stepTitle")}
          </span>
        </button>
      </h2>
      <div
        id="abortion-checklist-collapseFive"
        className="accordion-collapse collapse show"
        aria-labelledby="abortion-checklist-headingFive"
      >
        {/* CONTENT */}
        <p className="disclaimer-ribbon-bluish">{t("appliesToEveryone")}</p>
        {inClinic ? (
          <p className="single-step-accordion-txt-only">
            {t("accordionStep5.item1Txt_inClinic")}
          </p>
        ) : (
          <p className="single-step-accordion-txt-only">
            {t("accordionStep5.item1Txt")}
          </p>
        )}
        {!inClinic ? (
          <p className="single-step-accordion-txt-only">
            {t("accordionStep5.item2Txt")}{" "}
            <a
              href={t("accordionStep5.item2Href")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("accordionStep5.item2Link")}
            </a>
          </p>
        ) : (
          <p className="single-step-accordion-txt-only">
            {t("accordionStep5.item2Txt_inClinic")}{" "}
            <a
              href={t("accordionStep5.item2Href_inClinic")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("accordionStep5.item2Link")}
            </a>
          </p>
        )}
        <div className="step5-disclaimer-container">
          <div className="step5-disclaimer-txt-wrapper">
            <h3>{t("accordionStep5.disclaimerTitle")}</h3>
            <p>
              {t("accordionStep5.disclaimerTxtPart1")}
              <a
                href={t("accordionStep5.disclaimerHref1")}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("accordionStep5.disclaimerLink1")}
              </a>
              {t("accordionStep5.or")}
              <a
                href={t("accordionStep5.disclaimerHref2")}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("accordionStep5.disclaimerLink2")}
              </a>
              {t("accordionStep5.disclaimerTxtPart2")}
            </p>
          </div>
          <div className="step5-disclaimer-chat-icon-wrapper">
            <img src={chatIcon} alt="chat-icon" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Step5;
