import React from "react";
import { useTranslation } from "react-i18next";
import bulletPoint from "assets/black-circle.svg";
import "./SingleStepAccordion.scss";

const Step2 = ({ refProp }) => {
  const { t } = useTranslation();

  return (
    <section
      className="single-step-accordion-container"
      id="scrollspyAccordionStep2"
      ref={refProp}
    >
      <h2 id="abortion-checklist-headingTwo">
        <button
          className="accordion-button accordion-header"
          data-bs-toggle="collapse"
          data-bs-target="#abortion-checklist-collapseTwo"
          aria-expanded="false"
          aria-controls="abortion-checklist-collapseTwo"
        >
          <span className="accordion-step-number">
            {t("accordionStep2.stepNumber")}
            <span className="hide-it-on-mobile">:</span>
          </span>
          <span className="accordion-step-title">
            {t("accordionStep2.stepTitle")}
          </span>
        </button>
      </h2>
      <div
        id="abortion-checklist-collapseTwo"
        className="accordion-collapse collapse show"
        aria-labelledby="abortion-checklist-headingTwo"
      >
        {/* CONTENT */}
        <p className="disclaimer-ribbon-bluish">{t("appliesToEveryone")}</p>
        <div className="single-step-accordion-checkbox-wrapper">
          <img src={bulletPoint} alt="rectangle-icon" id="step2Item1" />
          <label htmlFor="step2Item1">
            <b>{t("accordionStep2.itemTxtBold")}</b>
            {t("accordionStep2.itemTxt")}{" "}
            <a
              href={t("accordionStep2.itemHref1")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("accordionStep2.itemLink1")}
            </a>
            {t("accordionStep2.or")}{" "}
            <a
              href={t("accordionStep2.itemHref2")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("accordionStep2.itemLink2")}
            </a>
          </label>
        </div>
      </div>
    </section>
  );
};

export default Step2;
