import React from "react";
import { useTranslation } from "react-i18next";
import securityIcon from "assets/security.svg";
import bulletPoint from "assets/black-circle.svg";
import "./SingleStepAccordion.scss";

const Step1 = ({ refProp }) => {
  const { t } = useTranslation();

  return (
    <section
      className="single-step-accordion-container"
      id="scrollspyAccordionStep1"
      ref={refProp}
    >
      <h2 id="abortion-checklist-headingOne">
        <button
          className="accordion-button accordion-header"
          data-bs-toggle="collapse"
          data-bs-target="#abortion-checklist-collapseOne"
          aria-expanded="false"
          aria-controls="abortion-checklist-collapseOne"
        >
          <span className="accordion-step-number">
            {t("accordionStep1.stepNumber")}
            <span className="hide-it-on-mobile">:</span>
          </span>
          <span className="accordion-step-title">
            {t("accordionStep1.stepTitle")}
          </span>
        </button>
      </h2>
      <div
        id="abortion-checklist-collapseOne"
        className="accordion-collapse collapse show"
        aria-labelledby="abortion-checklist-headingOne"
      >
        {/* CONTENT */}
        <p className="disclaimer-ribbon-yellow">{t("variesStateByState")}</p>
        <p className="accordion-txt-smaller">
          {t("accordionStep1.stepSubtitle")}
        </p>
        <div className="single-step-accordion-checkbox-wrapper">
          <img src={bulletPoint} alt="rectangle-icon" id="step1Item1" />
          <label htmlFor="step1Item1">
            <a
              href={t("accordionStep1.item1Href")}
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>{t("accordionStep1.item1Link")}</b>
            </a>
            {t("accordionStep1.item1Txt")}
          </label>
        </div>
        <div className="single-step-accordion-checkbox-wrapper">
          <img src={bulletPoint} alt="rectangle-icon" id="step1Item2" />
          <label htmlFor="step1Item2">
            <b>{t("accordionStep1.item2TxtStrong")}</b>
            {t("accordionStep1.item2Txt")}
          </label>
        </div>
        <div className="single-step-accordion-checkbox-wrapper">
          <img src={bulletPoint} alt="rectangle-icon" id="step1Item3" />
          <label htmlFor="step1Item3">
            <b>{t("accordionStep1.item3TxtStrong")}</b>
            {t("accordionStep1.item3Txt")}{" "}
            <a
              href={t("accordionStep1.item3Href")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("accordionStep1.item3Link")}
            </a>{" "}
            {t("accordionStep1.item3TxtPart2")}
          </label>
        </div>

        <div className="step1-disclaimer-container">
          <p>
            {t("accordionStep1.disclaimerItemTxt")}{" "}
            <a
              href={t("accordionStep1.disclaimerItemHref")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("accordionStep1.disclaimerItemLink")}
            </a>
            {t("accordionStep1.disclaimerItemTxtAfterLink")}
          </p>
          <img src={securityIcon} alt="icon" />
        </div>
      </div>
    </section>
  );
};

export default Step1;
