import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import closeIcon from "assets/close-btn.svg";

import "./SearchBox.scss";

const SearchBox = (props) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");

  const depositSearchValue = (event) => {
    setSearchValue(event.target.value);
  };

  const goToSearchPage = () => {
    window.location.assign(`${t("header.searchURL")}${searchValue}`);
  }

  return (
    <section id="ac-header-search-box-container">
      <div className="ac-header-search-box-inner-wrapper">
        <img src={closeIcon} alt="close" onClick={props.checkIsSearchOpen} />

        <p>{t("header.search")}</p>
        <div className="ac-header-search-input-wrapper">
          <input
            value={searchValue}
            onChange={(event) => depositSearchValue(event)}
            type="text"
          />
          <span onClick={goToSearchPage}>{t("header.find")}</span>
        </div>
      </div>
    </section>
  );
};

export default SearchBox;
