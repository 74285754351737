import React from "react";
import { useTranslation } from "react-i18next";
import arrowIcon from "assets/breadcrumbs-arrow.svg";
import "./IntroBanner.scss";

const IntroBanner = ({ scrollPosition }) => {
  const { t } = useTranslation();
  let stickyClass = "";

  if (scrollPosition > 0) {
    stickyClass = "sticky-banner";
  }

  return (
    <section id="intro-banner-container" className={stickyClass}>
      <div className="intro-banner-inner-wrapper">
        <div className="intro-banner-breadcrumbs-wrapper">
        <a href={t("introBanner.homeHref")}>{t("introBanner.home")}</a>
          <img src={arrowIcon} alt="arrow" />
          <a
            className="responsive-adj-show-on-550"
            href={t("introBanner.learnHref")}
          >
            {t("introBanner.learn")}
          </a>
          <img
            className="responsive-adj-show-on-550"
            src={arrowIcon}
            alt="arrow"
          />
          <a
            className="responsive-adj-show-on-642"
            href={t("introBanner.abortionHref")}
          >
            {t("introBanner.abortion")}
          </a>
          <p className="responsive-adj-hide-on-642 introbanner-breadcrumbs-dots">
            ...
          </p>
          <img src={arrowIcon} alt="arrow" />
          <a href={t("introBanner.stepByStepGuideHref")}>
            {t("introBanner.stepByStepGuide")}
          </a>
        </div>

        <div className="banner-txt-wrapper">
          <h1>{t("introBanner.title")}</h1>
          <p className="banner-main-subtitle">
            {t("introBanner.mainSubtitle")}
          </p>
          <br />
          <p className="banner-main-subtitle banner-subtitle-desktop">
            {t("introBanner.subtitleHeading")}
          </p>
          <p className="banner-subtitle banner-subtitle-desktop hide-it-on-mobile">
            {t("introBanner.subtitle")}
            <a
              href={`${t("introBanner.subtitleHref")}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("introBanner.subtitleLink")}
            </a>
          </p>
        </div>
      </div>
      <p className="banner-subtitle banner-subtitle-mobile hide-it-on-desktop">
        {t("introBanner.subtitle")}
        <a
          href={`${t("introBanner.subtitleHref")}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("introBanner.subtitleLink")}
        </a>
      </p>
    </section>
  );
};

export default IntroBanner;
