import React from "react";
import { useTranslation } from "react-i18next";
import PPlogo from "assets/planned-parenthood-stacked-tag.svg";
import instagramLogo from "assets/instagram.svg";
import facebookLogo from "assets/facebook.svg";
import twitterLogo from "assets/twitter.svg";
import tumblrLogo from "assets/tumblr.svg";
import youtubeLogo from "assets/youtube.svg";
import "./Footer.scss";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <section id="ac-footer-container">
      <div className="ac-footer-wrapper">
        <div className="footer-icons">
          <img src={PPlogo} alt="logo" />
          <div className="social-network-icons">
            <a
              href="https://www.facebook.com/PlannedParenthood/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebookLogo} alt="icon" />
            </a>
            <a
              href="https://twitter.com/PPFA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitterLogo} alt="icon" />
            </a>
            <a
              href="https://plannedparenthood.tumblr.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={tumblrLogo} alt="icon" />
            </a>
            <a
              href="https://www.instagram.com/plannedparenthood/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagramLogo} alt="icon" />
            </a>
            <a
              href="https://www.youtube.com/user/plannedparenthood"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={youtubeLogo} alt="icon" />
            </a>
          </div>
        </div>
        <div className="footer-site-text">
          <p>{t("footer.infoText")}</p>
        </div>
        <div className="copyright-legal">
          <div className="copyright">
            <p> &copy; 2022 Planned Parenthood Federation of America Inc.</p>
          </div>
          <div className="legal-links">
            <a
              href={t("footer.privacyPolicyHref")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("footer.privacyPolicy")}
            </a>
            <a
              href={t("footer.termsOfUseHref")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("footer.termsOfUse")}
            </a>
            <a
              href={t("footer.contactUsHref")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("footer.contactUs")}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
