import React, { useState, useEffect } from "react";
import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AbortionChecklistApp from "./Components/AbortionChecklistApp/AbortionChecklistApp";

const App = () => {
  const [inClinic, setInClinic] = useState(false);
  const [isBreadcrumbsInline, setIsBreadcrumbsInline] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  let firstStepPadding = "";

  useEffect(() => {
    window.addEventListener("scroll", itScrolls);
  }, [isBreadcrumbsInline]);

  const itScrolls = () => {
    setScrollPosition(window.scrollY);
    if (
      !isBreadcrumbsInline &&
      window.innerWidth < 1080 &&
      window.pageYOffset >= 1
    ) {
      setIsBreadcrumbsInline(true);
    } else if (
      isBreadcrumbsInline &&
      window.innerWidth < 1080 &&
      window.pageYOffset <= 1
    ) {
      setIsBreadcrumbsInline(false);
    }
  };

  if (scrollPosition > 0) {
    firstStepPadding = "padding-bottom";
  }

  useEffect(() => {
    window.addEventListener("resize", checksIsBreadcrumbsInline);
  }, []);

  const checksIsBreadcrumbsInline = () => {
    if (window.innerWidth >= 1080) {
      setIsBreadcrumbsInline(false);
    }
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/es/guia-para-el-aborto/"
          element={
            <AbortionChecklistApp
              setInClinic={setInClinic}
              inClinic={inClinic}
              isBreadcrumbsInline={isBreadcrumbsInline}
              lang="English"
              scrollPosition={scrollPosition}
              firstStepPadding={firstStepPadding}
            />
          }
        />
        <Route
          path="/abortion-checklist/"
          element={
            <AbortionChecklistApp
              setInClinic={setInClinic}
              inClinic={inClinic}
              isBreadcrumbsInline={isBreadcrumbsInline}
              lang="Español"
              scrollPosition={scrollPosition}
              firstStepPadding={firstStepPadding}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
