import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ppLogo from "assets/header-pp-logo.svg";
import getCareIcon from "assets/header-get-care.svg";
import getInvolvedIcon from "assets/header-get-involved.svg";
import learnIcon from "assets/header-learn-logo.svg";
import searchIcon from "assets/header-search.svg";
import SearchBox from "Components/SearchBox/SearchBox";

import "./Header.scss";

const Header = (props) => {
  const { t } = useTranslation();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  let stickyClass = "";

  const checkIsSearchOpen = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  if (props.scrollPosition > 0) {
    stickyClass = "sticky-header";
  }

  return (
    <section id="ac-header-container" className={stickyClass}>
      <div className="ac-header-top-wrapper">
        <img src={ppLogo} alt="logo" className="hide-it-on-desktop" />
        <div className="d-flex w-50">
          <a href= {props.lang === "Español" ? "/es/guia-para-el-aborto/" : "/abortion-checklist/"}
            className="lang-switch-btn ac-header-top-lang-switch-btn"
          >
            {props.lang}
          </a>
          <a
            className="ac-header-donate-btn hide-it-on-desktop"
            href="https://www.weareplannedparenthood.org/onlineactions/H8h-OMpbvEm9bRKX6BnDxw2?fb=true"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("header.donate")}
          </a>
        </div>
      </div>

      <div className="ac-header-bottom-wrapper">
        <img src={ppLogo} alt="logo" className="hide-it-on-mobile" />

        <div className="ac-header-bottom-menu-items-container">
          <a
            className="ac-header-main-menu-item-wrapper"
            href={t("header.learnHref")}
          >
            <img src={learnIcon} alt="learn" className="hide-it-on-desktop" />
            <span>{t("header.learn")}</span>
          </a>
          <a
            className="ac-header-main-menu-item-wrapper"
            href={t("header.getCareHref")}
          >
            <img src={getCareIcon} alt="learn" className="hide-it-on-desktop" />
            <span>{t("header.getCare")}</span>
          </a>
          <a
            className="ac-header-main-menu-item-wrapper"
            href={t("header.getInvolvedHref")}
          >
            <img
              src={getInvolvedIcon}
              alt="learn"
              className="hide-it-on-desktop"
            />
            <span>{t("header.getInvolved")}</span>
          </a>
          <a
            className="ac-header-donate-btn hide-it-on-mobile"
            href="https://www.weareplannedparenthood.org/onlineactions/H8h-OMpbvEm9bRKX6BnDxw2?fb=true"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("header.donate")}
          </a>
          <div
            onClick={checkIsSearchOpen}
            className="ac-header-main-menu-item-wrapper ac-header-search-icon-wrapper"
          >
            <img src={searchIcon} alt="learn" />
            <span>{t("header.search")}</span>
          </div>
        </div>
      </div>
      {isSearchOpen && <SearchBox checkIsSearchOpen={checkIsSearchOpen} />}
    </section>
  );
};

export default Header;
