import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Header from "../Header/Header";
import IntroBanner from "../IntroBanner/IntroBanner";
import BreadCrumbsNavigation from "../BreadCrumbsNavigation/BreadCrumbsNavigation";
import AbortionTypeDisclaimer from "../AbortionTypeDisclaimer/AbortionTypeDisclaimer";
import Step1 from "../SingleStepAccordion/Step1";
import Step2 from "../SingleStepAccordion/Step2";
import Step3 from "../SingleStepAccordion/Step3";
import Step4 from "../SingleStepAccordion/Step4";
import Step5 from "../SingleStepAccordion/Step5";
import downloadPdfIcon from "../../assets/download-pdf-icon.svg";
import Footer from "../Footer/Footer";
import "./AbortionChecklistApp.scss";

const AbortionChecklistApp = (props) => {
  const { i18n, t } = useTranslation();
  const [abAnimationClass, setAbAnimationClass] = useState("ab-animation-1");
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);

  useEffect(() => {
    i18n.changeLanguage(props.lang === "Español" ? "English" : "Español");
  }, [props.lang]);

  const changeAB = (data) => {
    props.setInClinic(data);
    abAnimationClass === "ab-animation-1"
      ? setAbAnimationClass("ab-animation-2")
      : setAbAnimationClass("ab-animation-1");
  };

  const stickyPositionMobile =
    window.innerWidth <= 1080
      ? ref1.current?.getBoundingClientRect().top + window.scrollY
      : 0;

  const stickyPosition =
    ref3.current?.getBoundingClientRect().top + window.scrollY;

  const bannerHeightForScroll = document.querySelector(
    "#intro-banner-container"
  )?.clientHeight;

  const stickyHeightForScroll = document.querySelector(
    "#abortion-type-disclaimer-container"
  )?.clientHeight;

  // Active step on scroll ********************************************
  const step1 =
    ref1.current?.getBoundingClientRect().top +
    window.scrollY -
    bannerHeightForScroll -
    stickyHeightForScroll -
    94;

  const step2 =
    ref2.current?.getBoundingClientRect().top +
    window.scrollY -
    bannerHeightForScroll -
    stickyHeightForScroll -
    94;

  const step3 =
    ref3.current?.getBoundingClientRect().top +
    window.scrollY -
    bannerHeightForScroll -
    stickyHeightForScroll -
    94;

  const step4 =
    ref4.current?.getBoundingClientRect().top +
    window.scrollY -
    bannerHeightForScroll -
    94;

  const step5 =
    ref5.current?.getBoundingClientRect().top +
    window.scrollY -
    bannerHeightForScroll -
    94;

  if (window.scrollY >= step1 - 20 && window.scrollY <= step1 + 100) {
    document.getElementById("step-2").classList.remove("active");
    document.getElementById("step-3").classList.remove("active");
    document.getElementById("step-4").classList.remove("active");
    document.getElementById("step-5").classList.remove("active");
    document.getElementById("step-1").classList.add("active");
  } else if (window.scrollY >= step2 - 100 && window.scrollY <= step2 + 100) {
    document.getElementById("step-1").classList.remove("active");
    document.getElementById("step-3").classList.remove("active");
    document.getElementById("step-4").classList.remove("active");
    document.getElementById("step-5").classList.remove("active");
    document.getElementById("step-2").classList.add("active");
  } else if (window.scrollY >= step3 - 100 && window.scrollY <= step3 + 100) {
    document.getElementById("step-1").classList.remove("active");
    document.getElementById("step-2").classList.remove("active");
    document.getElementById("step-4").classList.remove("active");
    document.getElementById("step-5").classList.remove("active");
    document.getElementById("step-3").classList.add("active");
  } else if (window.scrollY >= step4 - 100 && window.scrollY <= step4 + 100) {
    document.getElementById("step-1").classList.remove("active");
    document.getElementById("step-2").classList.remove("active");
    document.getElementById("step-3").classList.remove("active");
    document.getElementById("step-5").classList.remove("active");
    document.getElementById("step-4").classList.add("active");
  } else if (window.scrollY >= step5 - 100 && window.scrollY <= step5 + 100) {
    document.getElementById("step-1").classList.remove("active");
    document.getElementById("step-2").classList.remove("active");
    document.getElementById("step-3").classList.remove("active");
    document.getElementById("step-4").classList.remove("active");
    document.getElementById("step-5").classList.add("active");
  }

  const handleClick = (e, ref) => {
    e.preventDefault();

    const bannerHeight = document.querySelector(
      "#intro-banner-container"
    )?.clientHeight;

    const stickyHeight = document.querySelector(
      "#abortion-type-disclaimer-container"
    )?.clientHeight;

    const elementTopPosition =
      ref.current.getBoundingClientRect().top + window.scrollY;

    const addSpaceForSticky =
      elementTopPosition <= stickyPosition ? stickyHeight : 0;

    if (
      ref.current.id === "scrollspyAccordionStep3" &&
      elementTopPosition !== stickyPosition
    ) {
      window.scroll({
        top: elementTopPosition - bannerHeight - stickyHeight - 94,
        behavior: "auto",
      });
    } else {
      window.scroll({
        top: elementTopPosition - bannerHeight - addSpaceForSticky - 94,
        behavior: "auto",
      });
    }
  };

  return (
    <div>
      <Header
        changeLang={props.changeLang}
        lang={props.lang}
        scrollPosition={props.scrollPosition}
      />
      <IntroBanner scrollPosition={props.scrollPosition} />
      <div className="abortion-checklist-main-content-container">
        <div className="breadcrumbs-navigation-outside-wrapper">
          <BreadCrumbsNavigation
            onTypeChange={changeAB}
            inClinic={props.inClinic}
            isBreadcrumbsInline={props.isBreadcrumbsInline}
            handleFunc={handleClick}
            refProp1={ref1}
            refProp2={ref2}
            refProp3={ref3}
            refProp4={ref4}
            refProp5={ref5}
          />
        </div>
        <div className="accordion" id="abortion-checklist-container">
          <AbortionTypeDisclaimer
            inClinic={props.inClinic}
            positionOfScroll={props.scrollPosition}
            hidePosition={stickyPosition}
            hidePositionMobile={stickyPositionMobile}
          />
          <div
            className={`horizontal-step-divider step ${props.firstStepPadding}`}
          ></div>
          <div className={abAnimationClass}>
            <Step1 scrollPosition={props.scrollPosition} refProp={ref1} />
            <div className="horizontal-step-divider"></div>
            <Step2 refProp={ref2} />
            <div className="horizontal-step-divider"></div>
            <Step3 refProp={ref3} />
            <div className="horizontal-step-divider"></div>
            <Step4 inClinic={props.inClinic} refProp={ref4} />
            <div className="horizontal-step-divider"></div>
            <Step5 inClinic={props.inClinic} refProp={ref5} />
            <div className="horizontal-step-divider"></div>
            <div className="pdf-download-disclaimer-wrapper-mobile">
              <img src={downloadPdfIcon} alt="download-pdf-icon" />
              <a
                href={t("downloadPdfHref")}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("downloadPdf")}
              </a>
            </div>
            <div className="horizontal-step-divider"></div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AbortionChecklistApp;
