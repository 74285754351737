import React from "react";
import { useTranslation } from "react-i18next";
import typeChecmark from "assets/type-selected.svg";
import typeClinic from "assets/clinic-is-selected.svg";
import typeMedication from "assets/abortion-pill_small.svg";
import downloadPdfIcon from "assets/download-pdf-icon.svg";
import "./BreadCrumbsNavigation.scss";

const BreadCrumbsNavigation = (props) => {
  const { t } = useTranslation();

  return (
    <section
      id="breadcrumbs-navigation-container"
      className={`abortion-type-container ${
        props.isBreadcrumbsInline ? "its-inline" : ""
      }`}
    >
      <div
        className={`abortion-type-container ${
          props.isBreadcrumbsInline ? "its-inline" : ""
        }`}
      >
        {/* two main buttons */}
        <button
          onClick={() => props.onTypeChange(false)}
          className={props.inClinic ? "" : "type-selected"}
        >
          <div className="btn-txt-wrapper">
            {t("breadCrumbsNavigation.title1Part1")}{" "}
            <br className="hide-it-on-scroll" />
            <span className="">{t("breadCrumbsNavigation.title1Part2")}</span>
          </div>
          <img src={typeMedication} alt="icon" className="type-icon" />
          <img
            src={typeChecmark}
            alt="checked"
            className={`${props.inClinic ? "d-none" : ""} type-checkmark`}
          />
        </button>
        <button
          onClick={() => props.onTypeChange(true)}
          className={props.inClinic ? "type-selected" : ""}
        >
          <div className="btn-txt-wrapper">
            {t("breadCrumbsNavigation.title2Part1")}
            <br className="hide-it-on-scroll" />
            <span className="">{t("breadCrumbsNavigation.title2Part2")}</span>
          </div>
          <img src={typeClinic} alt="icon" className="type-icon" />

          <img
            src={typeChecmark}
            alt="checked"
            className={`${props.inClinic ? "" : "d-none"} type-checkmark`}
          />
        </button>
      </div>

      {/* checklist breadcrumbs */}
      <nav id="spyscrollNavigation">
        <ul
          className={`breadcrumbs-container ${
            props.isBreadcrumbsInline ? "its-inline" : ""
          }`}
        >
          <li>
            <a
              className="breadcrumb-step-wrapper"
              href=""
              onClick={(e) => props.handleFunc(e, props.refProp1)}
              id="step-1"
            >
              <span className="breadcrumb-step-number">1</span>
              <span className="breadcrumb-step-title">
                {t("breadCrumbsNavigation.step1Title")}
              </span>
            </a>
          </li>
          <span className="breadcrumb-step-divider-line"></span>
          <li>
            <a
              className="breadcrumb-step-wrapper"
              href=""
              onClick={(e) => props.handleFunc(e, props.refProp2)}
              id="step-2"
            >
              <span className="breadcrumb-step-number">2</span>
              <span className="breadcrumb-step-title">
                {t("breadCrumbsNavigation.step2Title")}
              </span>
            </a>
          </li>
          <span className="breadcrumb-step-divider-line"></span>
          <li>
            <a
              className="breadcrumb-step-wrapper"
              href=""
              onClick={(e) => props.handleFunc(e, props.refProp3)}
              id="step-3"
            >
              <span className="breadcrumb-step-number">3</span>
              <span className="breadcrumb-step-title">
                {t("breadCrumbsNavigation.step3Title")}
              </span>
            </a>
          </li>
          <span className="breadcrumb-step-divider-line"></span>
          <li>
            <a
              className="breadcrumb-step-wrapper"
              href=""
              onClick={(e) => props.handleFunc(e, props.refProp4)}
              id="step-4"
            >
              <span className="breadcrumb-step-number">4</span>
              <span className="breadcrumb-step-title">
                {t("breadCrumbsNavigation.step4Title")}
              </span>
            </a>
          </li>
          <span className="breadcrumb-step-divider-line"></span>
          <li>
            <a
              className="breadcrumb-step-wrapper"
              href=""
              onClick={(e) => props.handleFunc(e, props.refProp5)}
              id="step-5"
            >
              <span className="breadcrumb-step-number">5</span>
              <span className="breadcrumb-step-title">
                {t("breadCrumbsNavigation.step5Title")}
              </span>
            </a>
          </li>
        </ul>
      </nav>

      <div className="pdf-download-disclaimer-wrapper-desktop">
        <img src={downloadPdfIcon} alt="download-pdf-icon" />
        <a
          href={t("downloadPdfHref")}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("downloadPdf")}
        </a>
      </div>
    </section>
  );
};

export default BreadCrumbsNavigation;
