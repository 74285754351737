import React from "react";
import { useTranslation } from "react-i18next";
import bulletPoint from "assets/black-circle.svg";
import "./SingleStepAccordion.scss";

const Step3 = ({ refProp }) => {
  const { t } = useTranslation();

  return (
    <section
      className="single-step-accordion-container"
      id="scrollspyAccordionStep3"
      ref={refProp}
    >
      <h2 id="abortion-checklist-headingThree">
        <button
          className="accordion-button accordion-header"
          data-bs-toggle="collapse"
          data-bs-target="#abortion-checklist-collapseThree"
          aria-expanded="false"
          aria-controls="abortion-checklist-collapseThree"
        >
          <span className="accordion-step-number">
            {t("accordionStep3.stepNumber")}
            <span className="hide-it-on-mobile">:</span>
          </span>
          <span className="accordion-step-title">
            {t("accordionStep3.stepTitle")}
          </span>
        </button>
      </h2>

      <div
        id="abortion-checklist-collapseThree"
        className="accordion-collapse collapse show"
        aria-labelledby="abortion-checklist-headingThree"
      >
        {/* CONTENT */}
        <p className="disclaimer-ribbon-yellow">{t("variesStateByState")}</p>
        <p className="accordion-txt-smaller">
          {t("accordionStep3.stepSubtitle")}
        </p>
        <div className="single-step-accordion-checkbox-wrapper">
          <img src={bulletPoint} alt="rectangle-icon" id="step3Item1" />
          <label htmlFor="step3Item1">
            <b>{t("accordionStep3.item1TxtStrong")}</b>
            {t("accordionStep3.item1Txt")}
          </label>
        </div>

        <div className="single-step-accordion-checkbox-wrapper">
          <img src={bulletPoint} alt="rectangle-icon" id="step3Item2" />
          <label htmlFor="step3Item2">
            <b>{t("accordionStep3.item2TxtStrong")}</b>
            {t("accordionStep3.item2Txt")}
          </label>
        </div>

        <div className="single-step-accordion-checkbox-wrapper">
          <img src={bulletPoint} alt="rectangle-icon" id="step3Item3" />
          <label htmlFor="step3Item3">
            <b>{t("accordionStep3.item3TxtStrong")}</b>
            <a
              href={t("accordionStep3.item3Href")}
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>{t("accordionStep3.item3TxtLink")}</b>
            </a>
            {t("accordionStep3.item3Txt")}
          </label>
        </div>
      </div>
    </section>
  );
};

export default Step3;
