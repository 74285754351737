import React from "react";
import { useTranslation } from "react-i18next";
import iconClinic from "assets/type-disclaimer-icon-clinic.svg";
import iconMedication from "assets/abortion-pill_large.svg";
import "./AbortionTypeDisclaimer.scss";

const AbortionTypeDisclaimer = (props) => {
  const { t } = useTranslation();
  let stickyClass = "";

  if (
    window.innerWidth >= 1080 &&
    props.positionOfScroll <= props.hidePosition
  ) {
    stickyClass = "sticky";
  } else if (
    window.innerWidth > 440 &&
    window.innerWidth < 1080 &&
    props.positionOfScroll > 1 &&
    props.positionOfScroll < 350
  ) {
    stickyClass = "fixed";
  } else if (
    window.innerWidth <= 440 &&
    props.positionOfScroll > 1 &&
    props.positionOfScroll < 450
  ) {
    stickyClass = "fixed";
  }

  return (
    <section id="abortion-type-disclaimer-container" className={stickyClass}>
      <img src={props.inClinic ? iconClinic : iconMedication} alt="icon" />
      {!props.inClinic && (
        <p>
          <b>{t("breadCrumbsNavigation.subtitleStrong")}</b>
          {t("breadCrumbsNavigation.subtitleTxt")}
          <a
            href={t("breadCrumbsNavigation.subtitleHref")}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("breadCrumbsNavigation.subtitleLink")}
          </a>
        </p>
      )}
      {props.inClinic && (
        <p>
          <b>{t("breadCrumbsNavigation.subtitleStrong_inClinic")}</b>
          {t("breadCrumbsNavigation.subtitleTxt_inClinic")}
          <a
            href={t("breadCrumbsNavigation.subtitleHref_inClinic")}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("breadCrumbsNavigation.subtitleLink")}
          </a>
        </p>
      )}
    </section>
  );
};

export default AbortionTypeDisclaimer;
