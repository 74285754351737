import React from "react";
import { useTranslation } from "react-i18next";
import bulletPoint from "assets/black-circle.svg";
import "./SingleStepAccordion.scss";

const Step4 = (props) => {
  const { t } = useTranslation();
  const { inClinic } = props;

  return (
    <section
      className="single-step-accordion-container"
      id="scrollspyAccordionStep4"
      ref={props.refProp}
    >
      <h2 id="abortion-checklist-headingFour">
        <button
          className="accordion-button accordion-header"
          data-bs-toggle="collapse"
          data-bs-target="#abortion-checklist-collapseFour"
          aria-expanded="false"
          aria-controls="abortion-checklist-collapseFour"
        >
          <span className="accordion-step-number">
            {t("accordionStep4.stepNumber")}
            <span className="hide-it-on-mobile">:</span>
          </span>
          <span className="accordion-step-title">
            {t("accordionStep4.stepTitle")}
          </span>
        </button>
      </h2>

      <div
        id="abortion-checklist-collapseFour"
        className="accordion-collapse collapse show"
        aria-labelledby="abortion-checklist-headingFour"
      >
        {/* CONTENT */}
        <p className="disclaimer-ribbon-bluish">{t("appliesToEveryone")}</p>
        <div className="single-step-accordion-checkbox-wrapper">
          <img src={bulletPoint} alt="rectangle-icon" id="step4Item1" />
          {inClinic ? (
            <label htmlFor="step4Item1">
              <b>{t("accordionStep4.item1TxtStrong_inClinic")}</b>
              {t("accordionStep4.item1Txt_inClinic")}
            </label>
          ) : (
            <label htmlFor="step4Item1">
              <b>{t("accordionStep4.item1TxtStrong")}</b>
              {t("accordionStep4.item1TxtIn")}{" "}
              <a
                href={t("accordionStep4.item1Href")}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("accordionStep4.item1Link")}
              </a>
              {t("accordionStep4.item1Txt")}
              <br />
              <br />
              {t("accordionStep4.item1Txt2")}
            </label>
          )}
        </div>

        <div className="single-step-accordion-checkbox-wrapper">
          <img src={bulletPoint} alt="rectangle-icon" id="step4Item2" />
          {!inClinic ? (
            <label htmlFor="step4Item2">
              <b>{t("accordionStep4.item2TxtStrong")}</b>{" "}
              {t("accordionStep4.item2Txt")}
              <ul>
                <li>{t("accordionStep4.item2Bullet1Txt")}</li>
                <li>{t("accordionStep4.item2Bullet2Txt")}</li>
              </ul>
            </label>
          ) : (
            <label htmlFor="step4Item2">
              <b>{t("accordionStep4.item2TxtStrong_inClinic")}</b>
              <ul>
                <li>{t("accordionStep4.item2Bullet1Txt")}</li>
                <li>{t("accordionStep4.item2Bullet2Txt")}</li>
              </ul>
            </label>
          )}
        </div>
      </div>
    </section>
  );
};

export default Step4;
